import {
  BRAND_ORIGIN_FR,
  BRAND_ORIGIN_INTERNATIONAL,
  BRAND_ORIGINS_SELECT_OPTIONS,
  ContestedRequest,
  DATE_ISO8601,
  DateUtils,
  FRANCE,
  INTERNATIONAL,
  Publication,
  PUBLICATION_TYPE,
  Record,
  Title
} from '@inpi-marques/components'

class RecordService {
  /**
   * Transformer un contestedRequest en Record
   * @param contestedRequest
   */
  mappingContestedRequestToRecord = (contestedRequest: ContestedRequest) : Record => {
    return {
      id: contestedRequest.idRecord,
      createdAt: DateUtils.now(),
      numNat: contestedRequest.numnat,
      country: contestedRequest.isInternationalBrand ? INTERNATIONAL : FRANCE,
      originLabel: contestedRequest.isInternationalBrand ? BRAND_ORIGIN_INTERNATIONAL.label : BRAND_ORIGIN_FR.label,
      depositDate: contestedRequest.depositDate,
      details: {
        brand: {
          type: contestedRequest.brandType,
          text: contestedRequest.contestedBrand
        },
        priorities: contestedRequest.priority ? [contestedRequest.priority] : [],
        productsAndServicesVersions: contestedRequest.productsAndServicesVersions,
        depositDate: contestedRequest.depositDate,
        millesime: !contestedRequest.isInternationalBrand && DateUtils.isAfter(contestedRequest.depositDate, '1999-12-31') ? DateUtils.formatMillesimeYear(contestedRequest.depositDate) : undefined
      },
      publications: [{
        numBopi: contestedRequest.publicationNumber
      }]
    }
  }

  /**
   * Retourne la publication d'enregistrement, ou, si elle n'existe pas, la première publication
   * @param record
   * @returns
   */
  getContestedRequestPublication = (record: Record): Publication|undefined => {
    const publications: Publication[]|undefined = record.publications

    if (publications && publications.length) {
      return publications.find((publication: Publication) => publication.code === PUBLICATION_TYPE.REGISTRATION || publication.code === PUBLICATION_TYPE.REGISTRATIONAM) ?? publications[0]
    }
    return undefined
  }

  /**
   * Transformer un titre en Record
   * @param title
   */
  mappingTitleToRecord = (title: Title) : Record => {
    return {
      id: title.idRecord,
      createdAt: DateUtils.now(),
      numNat: title.numNat,
      country: title.origin === INTERNATIONAL ? INTERNATIONAL : title.origin,
      originLabel: BRAND_ORIGINS_SELECT_OPTIONS.find((origin) => origin.value === title.origin)?.label,
      depositDate: DateUtils.formateDateToFormat(title.depositDate, DATE_ISO8601) ?? undefined,
      details: {
        brand: {
          type: title.type,
          text: title.text
        },
        priorities: [],
        productsAndServicesVersions: title.productsAndServicesVersions ?? [],
        depositDate: DateUtils.formateDateToFormat(title.depositDate, DATE_ISO8601),
        millesime: title?.origin !== 'WO' && DateUtils.isAfter(title.depositDate, '1999-12-31') ? (title?.millesime ?? DateUtils.formatMillesimeYear(title.depositDate)) : undefined
      },
      publications: title.publications
    }
  }

  /**
   * Check si un record est publié
   * @param record
   * @returns
   */
  isPublished = (record: Record): boolean =>
    !!(record && record.publications && record.publications?.length > 0 && record.publications.some((publication) => (!publication.date || DateUtils.isBefore(publication.date, DateUtils.now())) && publication.numBopi)
    )
}

export default new RecordService()
