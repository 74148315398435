import DepositRoutes from 'component/deposit/DepositRoutes'
import DivisionRoutes from './component/division/DivisionRoutes'
import Home from 'component/home/Home'
import Login from 'component/login/Login'
import LoginEts from 'component/login/LoginEts'
import NotFound from 'component/notFound/NotFound'
import React from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import PrivateRoute from './common/PrivateRoute'
import AddressesBook from './component/addressesBook/AddressesBook'
import ETSRoutes from './component/ets/ETSRoutes'
import MyProfile from './component/myProfile/MyProfile'
import OneContact from './component/addressesBook/OneContact'
import BasketRoutes from './component/basket/BasketRoutes'
import SearchResults from './component/search/SearchResults'
import {
  PAYMENT_URL_CORRECTION,
  PAYMENT_URL_NOTIFICATION,
  PAYMENT_URL_REGULARIZATION,
  PAYMENT_URL_TRANSFORMATION_TN_TA
} from './constants/PaymentConstants'
import Payment from './component/payment/Payment'
import PaymentRoutes from './component/payment/PaymentRoutes'
import OppositionRoutes from './component/opposition/OppositionRoutes'
import NullityRoutes from './component/nullity/NullityRoutes'
import RevocationRoutes from './component/revocation/RevocationRoutes'
import Help from 'component/help/Help'
import InscriptionRoutes from './component/inscription/InscriptionRoutes'
import RenunciationRoutes from 'component/renunciation/RenunciationRoutes'
import FRMIRoutes from './component/frmi/FRMIRoutes'
import FRMIPostOperationsRoutes from 'component/frmiPostOperations/FRMIPostOperationRoutes'
import RenewalRoutes from './component/renewal/RenewalRoutes'
import OfficialDocumentRoute from './component/officialDocument/OfficialDocumentRoute'
import RevocationStatementRoutes from 'component/revocationStatement/RevocationStatementRoutes'
import { ENV_PRODUCTION } from '@inpi-marques/components'

const App = () => {
  const currentUser = useSelector((state: RootStateOrAny) => state.user.user)
  const isAuthenticated = !!currentUser

  const currentTokenETS = useSelector((state: RootStateOrAny) => state.user.tokenETS)

  return (
    <div className='global'>
      <Switch>
        <PrivateRoute
          path='/depots'
          component={DepositRoutes}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/oppositions'
          component={OppositionRoutes}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/nullites'
          component={NullityRoutes}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/decheances'
          component={RevocationRoutes}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/rattachements'
          component={ETSRoutes}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/divisions'
          component={DivisionRoutes}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/aide'
          component={Help}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/inscriptions'
          component={InscriptionRoutes}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/renonciations'
          component={RenunciationRoutes}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/frmi'
          component={FRMIRoutes}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/releves-de-decheance'
          component={RevocationStatementRoutes}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/operations-posterieures'
          component={FRMIPostOperationsRoutes}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/renouvellement'
          component={RenewalRoutes}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/contacts'
          component={AddressesBook}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/nouveau-contact'
          component={OneContact}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/contact/:id'
          component={OneContact}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/mon-compte'
          component={MyProfile}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/liste'
          component={BasketRoutes}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/recherche'
          component={SearchResults}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/documents-officiels'
          component={OfficialDocumentRoute}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path={`/${PAYMENT_URL_CORRECTION}/:id/paiement/:state`}
          component={(props) =>
            <Payment
              transactionId={props.match.params.id} state={props.match.params.state}
              type={PAYMENT_URL_CORRECTION}
            />}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path={`/${PAYMENT_URL_NOTIFICATION}/:id/paiement/:state`}
          component={(props) =>
            <Payment
              transactionId={props.match.params.id} state={props.match.params.state}
              type={PAYMENT_URL_NOTIFICATION}
            />}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path={`/${PAYMENT_URL_REGULARIZATION}/:id/paiement/:state`}
          component={(props) =>
            <Payment
              transactionId={props.match.params.id} state={props.match.params.state}
              type={PAYMENT_URL_REGULARIZATION}
            />}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path={`/${PAYMENT_URL_TRANSFORMATION_TN_TA}/:id/paiement/:state`}
          component={(props) =>
            <Payment
              transactionId={props.match.params.id} state={props.match.params.state}
              type={PAYMENT_URL_TRANSFORMATION_TN_TA}
            />}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/paiement'
          component={PaymentRoutes}
          authed={isAuthenticated}
        />
        <Route
          path='/ets/:token'
          render={(props) => (isAuthenticated && props.match.params.token === currentTokenETS
            ? (
              <Redirect to={{
                pathname: '/home',
                state: { from: props.location }
              }}
              />
            ) : (
              <LoginEts token={props.match.params.token} />
            )
          )}
        />
        <Route
          path='/login'
          render={(props: any) => (isAuthenticated
            ? (
              <Redirect to={{
                pathname: '/home',
                state: { from: props.location }
              }}
              />
            ) : (
              process.env.REACT_APP_ENV === ENV_PRODUCTION
                ? (
                  window.location.replace('https://procedures.inpi.fr/?/')
                ) : <Login />
            )
          )}
        />
        <PrivateRoute
          path='/'
          component={Home}
          authed={isAuthenticated}
        />
        <Route component={NotFound} />
      </Switch>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
    </div>
  )
}

export default App
