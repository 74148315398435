import {
  Contributor,
  Country,
  FRMI_TYPE_HOLDER_CHANGE,
  FRMI_TYPE_LICENCE_EDITION,
  FRMI_TYPE_POST_DESIGNATION_REGISTER
} from '@inpi-marques/components'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import FrmiContributorListFormItem from './FrmiContributorListFormItem'

interface FrmiContributorListFormProps {
    contributors: Contributor[],
    countries: Country[],
    fromFrmiExtension: boolean,
    code: string,
    contributorType: string,
    editContributor: (index: number) => void,
    deleteContributor: (index: number) => void,
    duplicateContributor: (index: number) => void,
    otherApplicantCodeToDuplicate?: string,
    subProcedureType?: string
}

const FrmiContributorListForm: FC<FrmiContributorListFormProps> = ({
  contributors,
  countries,
  fromFrmiExtension = false,
  code,
  contributorType,
  deleteContributor,
  editContributor,
  duplicateContributor,
  otherApplicantCodeToDuplicate,
  subProcedureType
}) => {
  /**
     * Lorsque l'on peut dupliquer un ancien intervenant (via le bouton Modifier les informations), l'affichage est différents, on aura 3 parties:
     *
     * importedContributors => les intervenants importés du Référentiel / base publique / madrid monitor
     * editedContributors => intervenant dupliqué
     * addedContributors => Intervenant ajouté via le bouton "Ajouter ......"
     *
     * Sinon, tout sera dans importedContributors pour un affichage classique.
     */
  const importedContributors: Contributor[] = contributors.filter((contributor: Contributor) => !contributor.state)
  const importedContributorsIndex = contributors.map((contributor: Contributor, index: number) => !contributor.state ? index : undefined).filter(value => value !== undefined)
  const editedContributors: Contributor[] = contributors.filter((contributor: Contributor) => contributor.state === 'EDITED')
  const editedContributorsIndex = contributors.map((contributor: Contributor, index: number) => contributor.state === 'EDITED' ? index : undefined).filter(value => value !== undefined)
  const addedContributors: Contributor[] = contributors.filter((contributor: Contributor) => contributor.state === 'ADDED')
  const addedContributorsIndex = contributors.map((contributor: Contributor, index: number) => contributor.state === 'ADDED' ? index : undefined).filter(value => value !== undefined)
  return (
    <>
      <div className='form-header'>
        <h2 className='mb-0 '>
          <FormattedMessage
            id={`contributor_${code}`}
          />
        </h2>
        <span className='small'>
          <FormattedMessage
            id={contributorType && `contributor_${contributorType}_${subProcedureType === FRMI_TYPE_POST_DESIGNATION_REGISTER.value ? 'frmi_' : ''}subtitle`}
            values={{
              a: (...chunks: ((string) []) | []) => (
                <a href={process.env.REACT_APP_URL_HELP} target='_blank' rel='noopener noreferrer'>{chunks}</a>),
              linebreak: <br />
            }}
          />
        </span>
      </div>

      {importedContributors.length > 0 && (
        <FrmiContributorListFormItem
          contributors={importedContributors}
          countries={countries}
          deleteContributor={deleteContributor}
          editContributor={editContributor}
          fromFrmiExtension={fromFrmiExtension}
          indexSuffix='imported'
          indexes={importedContributorsIndex}
          otherApplicantCodeToDuplicate={otherApplicantCodeToDuplicate}
          duplicateContributor={duplicateContributor}
          canContributorBeDuplicated={subProcedureType !== FRMI_TYPE_LICENCE_EDITION.value || (editedContributors.length === 0 && addedContributors.length === 0)}
          isHolderChange={subProcedureType === FRMI_TYPE_HOLDER_CHANGE.value}
        />

      )}
      {editedContributors.length > 0 && (
        <>
          <div>
            <FormattedMessage id={`contributor_${code}_edited_label`} />
          </div>
          <FrmiContributorListFormItem
            contributors={editedContributors}
            countries={countries}
            deleteContributor={deleteContributor}
            editContributor={editContributor}
            fromFrmiExtension={fromFrmiExtension}
            indexSuffix='edited'
            indexes={editedContributorsIndex}
            otherApplicantCodeToDuplicate={otherApplicantCodeToDuplicate}
            duplicateContributor={duplicateContributor}
            isHolderChange={subProcedureType === FRMI_TYPE_HOLDER_CHANGE.value}
          />
        </>

      )}
      {addedContributors.length > 0 && (
        <>
          <div>
            <FormattedMessage id={`contributor_${code}_${subProcedureType === FRMI_TYPE_LICENCE_EDITION.value ? 'edited' : 'added'}_label`} />
          </div>
          <FrmiContributorListFormItem
            contributors={addedContributors}
            countries={countries}
            deleteContributor={deleteContributor}
            editContributor={editContributor}
            fromFrmiExtension={fromFrmiExtension}
            indexSuffix='added'
            indexes={addedContributorsIndex}
            otherApplicantCodeToDuplicate={otherApplicantCodeToDuplicate}
            duplicateContributor={duplicateContributor}
            isHolderChange={subProcedureType === FRMI_TYPE_HOLDER_CHANGE.value}
          />
        </>

      )}
    </>
  )
}

export default FrmiContributorListForm
