import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import cloneDeep from 'lodash.clonedeep'
import { CardBlock, ProtectionExtension, Transaction } from '@inpi-marques/components'
import ContentService from '../../../../services/content/ContentService'
import { useDispatch } from 'react-redux'
import { storeTransactionUpdateRenewal } from '../../../../store/transaction/transactionActions'
import ProtectionsExtensionsListByTitle from './ProtectionsExtensionsListByTitle'

interface ProtectionExtensionProps {
  transaction: Transaction
}

/**
 * Extensions de protection pour les titres à renouveler
 * @param transaction
 * @param titles
 * @constructor
 */
const ProtectionExtensionRenewal: FC<ProtectionExtensionProps> = ({
  transaction
}) => {
  const dispatch = useDispatch()
  const [extensions, setExtensions] = useState<ProtectionExtension[]>([])
  const titles = transaction?.renewalDetails?.titles ?? []

  useEffect(() => {
    // Récupération de la liste des extensions de protections possibles
    ContentService.getProtectionExtensions().then(setExtensions)

    return () => {
      ContentService.cancelRequest()
    }
  }, [])

  /**
   * Mise à jour dans le state des extensions de protection
   * @param value
   * @param idProtectionExtension
   * @param indexTitle
   */
  const handleExtensionChange = (value: boolean, idProtectionExtension: number, indexTitle: number) => {
    const updatedTitles = cloneDeep(titles)
    if (!value) {
      updatedTitles[indexTitle] = {
        ...updatedTitles[indexTitle],
        protectionExtensions: updatedTitles[indexTitle]?.protectionExtensions?.filter((item: ProtectionExtension) => item?.id !== idProtectionExtension)
      }
    } else {
      const ext = extensions && extensions.find(ext => ext?.id === idProtectionExtension)
      if (!ext) {
        return
      }
      updatedTitles[indexTitle] = {
        ...updatedTitles[indexTitle],
        protectionExtensions: [...updatedTitles[indexTitle]?.protectionExtensions?.filter((extension: ProtectionExtension) => extension.id !== ext.id) ?? [], ext]
      }
    }

    dispatch(storeTransactionUpdateRenewal({
      ...transaction.renewalDetails,
      titles: updatedTitles
    }))
  }

  return (
    <div>
      <CardBlock
        shadow
        className='mt-3'
        bodyClassName='pb-2 pt-3'
        header={<FormattedMessage id='renewal_options_extension_title' />}
      >
        <span>
          <FormattedMessage
            id='renewal_options_extension_description'
            values={{
              a: (...chunks: ((string) []) | []) => (
                <a
                  href={process.env.REACT_APP_URL_HELP_EXTENSION} target='_blank'
                  rel='noopener noreferrer'
                >{chunks}
                </a>)
            }}
          />
        </span>
        <span>
          <FormattedMessage id='renewal_options_extension_warning' />
        </span>
        <div className='mt-3'>
          <ProtectionsExtensionsListByTitle
            titles={titles}
            extensions={extensions}
            handleExtensionChange={handleExtensionChange}
          />
        </div>
      </CardBlock>
    </div>
  )
}
export default ProtectionExtensionRenewal
