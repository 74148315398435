import {
  isFilled,
  isMailFormatValid,
  isNumeroSiren,
  isSirenAlgoLuhnValid,
  isTelephoneValid, isTextLatin,
  messageIsFilled
} from '../../utils/validationUtils'
import Message from '../../constants/Message'
import { Contributor } from '../../interfaces/contributors/ContributorsInterfaces'
import { PERSONNE_MORALE, PERSONNE_PHYSIQUE } from '../../constants/contributors/PersonConstants'
import { FRANCE } from '../../constants/contributors/AddressConstants'
import {
  PROCEDURE_FRMI,
  PROCEDURE_NULLITY,
  PROCEDURE_OPPOSITION,
  PROCEDURE_REVOCATION,
  PROCEDURE_OFFICIAL_DOCUMENT
} from '../../constants/ProcedureConstants'
import {
  CONTRIBUTOR_AGENT, CONTRIBUTOR_AGENT_DEPOSITOR, CONTRIBUTOR_AGENTS_TMC,
  CONTRIBUTOR_BENEFICIARIES,
  CONTRIBUTOR_DEPOSITORS,
  CONTRIBUTOR_HOLDERS,
  CONTRIBUTOR_HOLDERS_CONTESTED_MARK, CONTRIBUTOR_OLD_HOLDERS, CONTRIBUTOR_OPPONENTS,
  CONTRIBUTOR_ORGANISERS,
  CONTRIBUTOR_OTHER_APPLICANTS,
  DEPOSIT_CONTRIBUTORS_TYPES,
  FRMI_CONTRIBUTORS_TYPES,
  NULLITY_CONTRIBUTORS_TYPES
} from '../../constants/contributors/ContributorsConstants'
import { ENTITE } from '../../constants/contributors/LegalStatusFormationConstants'
import {
  FieldStatus,
  FRMI_TYPE_EXTENSION,
  INSCRIPTION_TYPE_ACT_TITLE,
  isDepositDepositor,
  Transaction
} from '@inpi-marques/components'

class PersonneFieldValidator {
  isPersonnePhysique = (contributor: Contributor = {}) => contributor.type === PERSONNE_PHYSIQUE.value

  isPersonneMorale = (contributor: Contributor = {}) => contributor.type === PERSONNE_MORALE.value

  validateCivility = (contributor: Contributor = {}) => {
    if (this.isPersonnePhysique(contributor) && !isFilled(contributor.civilityCode)) {
      return Message.required_field
    }
    return ''
  }

  validateLastName = (contributor: Contributor = {}, isMandatory = false) => {
    if ((this.isPersonnePhysique(contributor) || isMandatory) && !isFilled(contributor.lastname)) {
      return Message.required_field
    }
    if (contributor.lastname && !isTextLatin(contributor.lastname)) {
      return Message.text_not_latin
    }
    return ''
  }

  validateFirstName = (contributor: Contributor = {}, isMandatory = false) => {
    if ((this.isPersonnePhysique(contributor) || isMandatory) && !isFilled(contributor.firstname)) {
      return Message.required_field
    }
    if (contributor.firstname && !isTextLatin(contributor.firstname)) {
      return Message.text_not_latin
    }
    return ''
  }

  validateCompanyName = (contributor: Contributor = {}) => {
    if (this.isPersonneMorale(contributor) && !isFilled(contributor.companyName)) {
      return Message.required_field
    }
    if (contributor.companyName && !isTextLatin(contributor.companyName)) {
      return Message.text_not_latin
    }
    return ''
  }

  validateLegalForm = (contributor: Contributor = {}, contributorType?:string, isBO: boolean = false) => {
    if (this.isPersonneMorale(contributor) && contributorType === CONTRIBUTOR_HOLDERS_CONTESTED_MARK.value && isBO) {
      return ''
    } else if (this.isPersonneMorale(contributor) && !isFilled(contributor.legalForm)) {
      return Message.required_field
    }
    if (contributor.legalForm && !isTextLatin(contributor.legalForm)) {
      return Message.text_not_latin
    }
    return ''
  }

  validateCompanyNameFormation = (contributor: Contributor = {}) => {
    if (contributor.personFormation && !isFilled(contributor.companyNameFormation)) {
      return Message.required_field
    }
    if (contributor.companyNameFormation && !isTextLatin(contributor.companyNameFormation)) {
      return Message.text_not_latin
    }
    return ''
  }

  validateLegalFormFormation = (contributor: Contributor = {}, procedure?: string) => {
    if (contributor.personFormation && !isFilled(contributor.legalFormFormation) &&
      procedure !== PROCEDURE_REVOCATION.value && procedure !== PROCEDURE_NULLITY.value && procedure !== PROCEDURE_OPPOSITION.value) {
      return Message.required_field
    }
    if (contributor.legalFormFormation && !isTextLatin(contributor.legalFormFormation)) {
      return Message.text_not_latin
    }

    return ''
  }

  validateLegalFormDescriptionFormation = (contributor: Contributor = {}, procedure?: string) => {
    if (contributor.personFormation && contributor?.legalFormFormation === ENTITE.value && !isFilled(contributor.descriptionFormation) &&
      procedure !== PROCEDURE_REVOCATION.value && procedure !== PROCEDURE_NULLITY.value && procedure !== PROCEDURE_OPPOSITION.value) {
      return Message.required_field
    }
    if (contributor.descriptionFormation && !isTextLatin(contributor.descriptionFormation)) {
      return Message.text_not_latin
    }

    return ''
  }

  validateSiren = (contributor: Contributor = {}) => {
    const { siren = '' } = contributor

    if (isFilled(siren)) {
      if (!isNumeroSiren(siren) || !isSirenAlgoLuhnValid(siren)) {
        return Message.error_numero_siren_format
      }
    }
    return ''
  }

  validatePhone = (contributor: Contributor = {}) => {
    const { phone = '' } = contributor
    const country = contributor.address?.country

    if (isFilled(phone) && country === FRANCE && !isTelephoneValid(phone)) {
      return Message.error_phone_format
    }
    return ''
  }

  /**
   * Validation d'un email
   * @param contributor
   * @param contributorType
   * @param subProcedureType
   * @returns
   */
  validateEmail = (contributor: Contributor = {}, contributorType?: string, subProcedureType?: string) => {
    const { email = '' } = contributor
    if (!contributor.email &&
      ((contributorType === CONTRIBUTOR_DEPOSITORS.value && subProcedureType !== FRMI_TYPE_EXTENSION.value) ||
        (contributorType === CONTRIBUTOR_AGENT.value && subProcedureType !== FRMI_TYPE_EXTENSION.value) ||
        (contributorType === CONTRIBUTOR_OTHER_APPLICANTS.value && subProcedureType === INSCRIPTION_TYPE_ACT_TITLE.value) ||
        contributorType === CONTRIBUTOR_ORGANISERS.value || contributorType === NULLITY_CONTRIBUTORS_TYPES.HOLDERS_CONTESTED_MARK.value ||
        contributorType === CONTRIBUTOR_OPPONENTS.value || contributorType === CONTRIBUTOR_AGENTS_TMC.value ||
        (contributorType === CONTRIBUTOR_OTHER_APPLICANTS.value && subProcedureType !== FRMI_TYPE_EXTENSION.value && contributor.code === CONTRIBUTOR_OLD_HOLDERS.value) ||
        contributorType === CONTRIBUTOR_AGENT_DEPOSITOR.value)) {
      return ''
    }

    if (!isFilled(email)) {
      return Message.required_field
    }
    if (!isMailFormatValid(email)) {
      return Message.error_email_format
    }
    if (email && !isTextLatin(email)) {
      return Message.text_not_latin
    }
    return ''
  }

  validateAll = (contributor: Contributor = {}, transaction?: Transaction, contributorType?: string, isBO: boolean = false, nationalityRequired?: boolean): FieldStatus => ({
    type: messageIsFilled(contributor.type),
    nationality: ((contributor?.type === PERSONNE_PHYSIQUE.value && transaction?.procedureType === PROCEDURE_FRMI.value) || (transaction && isDepositDepositor(transaction, contributorType) && !isBO)) &&
      nationalityRequired &&
      [DEPOSIT_CONTRIBUTORS_TYPES.DEPOSITORS.value, CONTRIBUTOR_HOLDERS.value, FRMI_CONTRIBUTORS_TYPES.HOLDERS.value, FRMI_CONTRIBUTORS_TYPES.LICENCEE.value, FRMI_CONTRIBUTORS_TYPES.OLD_LICENCEES.value].includes(contributorType) ? messageIsFilled(contributor?.nationality) : '',
    // On utilise un autre validateur pour les dépositors car la gestion du mail est différente.
    ...this.validateAllAgent(contributor, transaction, contributorType, isBO)
  })

  validateAllRecipient = (contributor: Contributor = {}, procedure?: string) => {
    return {
      type: messageIsFilled(contributor.type),
      ...this.validateAllRecipientDeposit(contributor, procedure)
    }
  }

  /**
   * Valide les champs utilisé dans plusieurs validateurs
   *
   * @param contributor
   * @param procedure
   * @param contributorType
   * @param isBO
   * @returns
   */
  validateCommonFields = (contributor: Contributor, procedure?: string, contributorType?: string, isBO: boolean = false): FieldStatus => ({
    civilityCode: isBO ? '' : this.validateCivility(contributor),
    lastname: this.validateLastName(contributor),
    firstname: this.validateFirstName(contributor),
    companyName: this.validateCompanyName(contributor),
    companyNameFormation: this.validateCompanyNameFormation(contributor),
    legalForm: procedure !== PROCEDURE_FRMI.value ? this.validateLegalForm(contributor, contributorType, isBO) : '',
    legalFormFormation: this.validateLegalFormFormation(contributor, procedure),
    descriptionFormation: this.validateLegalFormDescriptionFormation(contributor, procedure),
    siren: this.validateSiren(contributor),
    phone: this.validatePhone(contributor)
  })

  /**
   * Valide tous les champs d'un Mandataire
   * Un mandataire est quasi identique aux autres contributeurs, il n'a juste pas de type
   *
   * @param contributor
   * @param transaction
   * @param contributorType
   * @param isBO
   * @returns
   */
  validateAllAgent = (contributor: Contributor, transaction?: Transaction, contributorType?: string, isBO: boolean = false) => ({
    ...this.validateCommonFields(contributor, transaction?.procedureType, contributorType, isBO),
    email: ((contributorType === CONTRIBUTOR_BENEFICIARIES.value || contributorType === CONTRIBUTOR_OLD_HOLDERS.value) ||
      (contributorType === CONTRIBUTOR_HOLDERS.value && transaction?.procedureType === PROCEDURE_OFFICIAL_DOCUMENT.value) ||
      transaction?.details) && isBO ? '' : this.validateEmail(contributor, contributorType, transaction?.subProcedureType)
  })

  /**
   * Valide tous les champs d'un Destinataire
   *
   * @param contributor
   * @param procedure
   * @param contributorType
   * @returns
   */
  validateAllRecipientDeposit = (contributor: Contributor, procedure?: string) => ({
    civilityCode: this.validateCivility(contributor),
    lastname: this.validateLastName(contributor),
    firstname: this.validateFirstName(contributor),
    companyName: this.validateCompanyName(contributor),
    legalForm: contributor.legalForm && !isTextLatin(contributor.legalForm) ? Message.text_not_latin : '',
    companyNameFormation: this.validateCompanyNameFormation(contributor),
    legalFormFormation: this.validateLegalFormFormation(contributor, procedure),
    descriptionFormation: this.validateLegalFormDescriptionFormation(contributor, procedure),
    siren: this.validateSiren(contributor),
    phone: this.validatePhone(contributor)
  })
}

export default new PersonneFieldValidator()
