import React, { Dispatch, FC } from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import AddressSearchField from './AddressSearchField'
import {
  Address,
  buildEventType,
  ContributorsOptionsForm,
  Country,
  EventType,
  FieldStatus,
  FRANCE,
  TextField
} from '../../index'
import CountrySelectField from './CountrySelectField'
import AddressApiService from '../../services/AddressApiService'

interface AddressFieldsProps extends WrappedComponentProps {
  address?: Address,
  options?: ContributorsOptionsForm,
  onChange: (event: EventType) => void,
  fieldStatus?: FieldStatus | any,
  getCountries: () => Promise<void | Country[]>,
  dispatch?: Dispatch<any>,
  resetError?: (fieldStatus: FieldStatus) => void,
  title?: string|undefined,
  propName?: string|undefined,
  subtitle?: string|undefined,
  fieldInputPrefix?: string,
  frmiTypeHolderChange?: boolean
}

const EMPTY_ADDRESS : Address = {
  building: '',
  label: '',
  complement: '',
  zipCode: '',
  city: '',
  country: '',
  state: ''
}

const AddressFields: FC<AddressFieldsProps> = ({
  address = EMPTY_ADDRESS,
  onChange,
  fieldStatus = {},
  intl,
  getCountries,
  dispatch,
  resetError,
  options,
  title = 'field_adresse_label',
  propName = 'address',
  subtitle,
  fieldInputPrefix,
  frmiTypeHolderChange
}) => {
  /**
   * A la selection d'une adresse dans l'autocompletion
   * @param address
   */
  const handleSelectSearch = (address: Address) => {
    onChange(buildEventType(propName, address))
  }

  /**
   * A la saisie de l'adresse dans les champs du formulaire
   * @param event
   */
  const handleChange = (event: EventType) => {
    const { name, value } = event.target
    const updatedAddress = {
      ...address,
      [name]: value
    }

    onChange(buildEventType(propName, updatedAddress))
  }

  return (
    <>
      {!options?.noTitle &&
        <div className='header-field flex-column d-flex'>
          <FormattedMessage id={title} />
          {subtitle &&
            <span className='small'>
              <FormattedMessage
                id={subtitle}
              />
            </span>}
        </div>}
      {
        options?.couldSearchAddress &&
          <div className='row'>
            <div className='col-12 col-md-8'>
              <AddressSearchField
                inputId='addressSearch'
                placeholder={intl.formatMessage({ id: 'placeholder_adresse_search' })}
                onSelect={handleSelectSearch}
                searchAddress={AddressApiService.searchAddress}
                dispatch={dispatch}
                resetError={resetError}
                disabled={options.allFieldReadOnly}
              />
            </div>
          </div>
      }

      <div className='row'>
        {!options?.noBuilding &&
          <div className='col-12 col-md-4'>
            <TextField
              nameFieldStatus={`${fieldInputPrefix ? `${fieldInputPrefix}_` : ''}building`}
              inputId='building'
              label={intl.formatMessage({ id: 'field_adresse_batiment_label' })}
              value={address?.building}
              onChange={handleChange}
              fieldStatus={fieldStatus}
              maxLength={100}
              dispatch={dispatch}
              resetError={resetError}
              readOnly={options?.allFieldReadOnly}
            />
          </div>}
        <div className='col-12 col-md-4'>
          <TextField
            nameFieldStatus={`${fieldInputPrefix ? `${fieldInputPrefix}_` : ''}label`}
            inputId='label'
            label={intl.formatMessage({ id: 'field_adresse_libelle_label' })}
            value={address?.label}
            onChange={handleChange}
            fieldStatus={fieldStatus}
            maxLength={100}
            required={!frmiTypeHolderChange}
            dispatch={dispatch}
            resetError={resetError}
            readOnly={options?.allFieldReadOnly}
          />
        </div>
        <div className='col-12 col-md-4'>
          <TextField
            nameFieldStatus={`${fieldInputPrefix ? `${fieldInputPrefix}_` : ''}complement`}
            inputId='complement'
            label={intl.formatMessage({ id: 'field_adresse_mention_label' })}
            value={address?.complement}
            onChange={handleChange}
            fieldStatus={fieldStatus}
            maxLength={100}
            dispatch={dispatch}
            resetError={resetError}
            readOnly={options?.allFieldReadOnly}
          />
        </div>
        {options?.displayPostOfficeBox &&
          <div className='col-12 col-md-4'>
            <TextField
              nameFieldStatus={`${fieldInputPrefix ? `${fieldInputPrefix}_` : ''}postOfficeBox`}
              inputId='postOfficeBox'
              label={intl.formatMessage({ id: 'field_adresse_post_office_box_label' })}
              value={address?.postOfficeBox}
              onChange={handleChange}
              fieldStatus={fieldStatus}
              maxLength={100}
              dispatch={dispatch}
              resetError={resetError}
              readOnly={options?.allFieldReadOnly}
            />
          </div>}
      </div>

      <div className='row'>
        <div className='col-12 col-md-4'>
          <TextField
            nameFieldStatus={`${fieldInputPrefix ? `${fieldInputPrefix}_` : ''}zipCode`}
            inputId='zipCode'
            label={intl.formatMessage({ id: 'field_adresse_code_postal_label' })}
            value={address?.zipCode}
            onChange={handleChange}
            fieldStatus={fieldStatus}
            maxLength={10}
            required={address?.country === FRANCE}
            dispatch={dispatch}
            resetError={resetError}
            readOnly={options?.allFieldReadOnly}
          />
        </div>
        <div className='col-12 col-md-4'>
          <TextField
            nameFieldStatus={`${fieldInputPrefix ? `${fieldInputPrefix}_` : ''}city`}
            inputId='city'
            label={intl.formatMessage({ id: 'field_adresse_ville_label' })}
            value={address?.city}
            onChange={handleChange}
            fieldStatus={fieldStatus}
            maxLength={50}
            required={!frmiTypeHolderChange}
            dispatch={dispatch}
            resetError={resetError}
            readOnly={options?.allFieldReadOnly}
          />
        </div>
        <div className='col-12 col-md-4'>
          <CountrySelectField
            nameFieldStatus={`${fieldInputPrefix ? `${fieldInputPrefix}_` : ''}country`}
            inputId='country'
            label={intl.formatMessage({ id: 'field_adresse_pays_label' })}
            value={address?.country}
            onChange={handleChange}
            fieldStatus={fieldStatus}
            required={!frmiTypeHolderChange}
            placeholder={intl.formatMessage({ id: 'placeholder_select' })}
            getCountries={getCountries}
            readOnly={options?.allFieldReadOnly}
          />
        </div>
      </div>
    </>
  )
}

export default injectIntl(AddressFields)
