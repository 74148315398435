import React, { FC, useEffect, useState } from 'react'
import {
  containsErrors,
  Contributor,
  CONTRIBUTOR_DEPOSITORS,
  ContributorService as CommonContributorService,
  ContributorValidator,
  DEFAULT_CONTRIBUTOR,
  DEPOSIT_CONTRIBUTORS_TYPES,
  DocumentType,
  EditorialBlock,
  FieldStatus,
  FilAriane,
  ModalComponent,
  PROCEDURE_RENEWAL,
  PROCESS_FAST_TRACK_RENEWAL,
  PROCESS_NORMAL,
  RENEWAL_CONTRIBUTORS_OPTIONS,
  RENEWAL_CONTRIBUTORS_TYPES,
  RENEWAL_TYPE_TOTAL,
  Transaction,
  TransactionDocument
} from '@inpi-marques/components'
import { FormattedMessage } from 'react-intl'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import StepContainer from '../../stepper/StepContainer'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import {
  storeTransactionInitRenewal,
  storeTransactionRemove,
  storeTransactionUpdate
} from 'store/transaction/transactionActions'
import TransactionService from 'services/transaction/TransactionService'
import ContentService from '../../../services/content/ContentService'
import Titles from '../../inscription/form/titles/Titles'
import TitlesValidator from '../../inscription/form/titles/validator/TitlesValidator'
import ContributorList from '../../contributors/ContributorList'
import ContributorValidatorAndSaveStore from '../../../services/contributors/ContributorValidatorAndSaveStore'
import ContributorFrontForm from '../../contributors/ContributorFrontForm'
import ContributorService from '../../../services/contributors/ContributorService'
import ContributorAgent from '../../contributors/ContributorAgent'
import Recap from '../../recap/Recap'
import OverviewValidator from '../../recap/validator/OverviewValidator'
import PaymentForm from '../../payment/PaymentForm'
import { PAYMENT_LABELS, PAYMENT_OBJECTS, PAYMENT_URL_RENEWAL } from '../../../constants/PaymentConstants'
import RenewalOptions from './options/RenewalOptions'
import RenewalOptionsValidator from './options/validator/RenewalOptionsValidator'
import ConfigurationService from '../../../services/configuration/ConfigurationService'
/* global localStorage */

interface RenewalFormProps extends RouteComponentProps {
    isNewTransaction: boolean
}

const RenewalForm: FC<RenewalFormProps> = ({
  history,
  match,
  isNewTransaction = false
}) => {
  const dispatch = useDispatch()
  const [showRgpd, setShowRgpd] = useState(false)
  const [rgpdContent, setRdpdContent] = useState('')
  const [required, setIsRequired] = useState<boolean>(false)
  const fieldStatus: FieldStatus = useSelector((state: RootStateOrAny) => state.transactionFieldStatus)
  const transaction: Transaction = useSelector((state: RootStateOrAny) => state.transaction)
  const [documents, setDocuments] = useState<TransactionDocument[]>(transaction.documents ?? [])
  const [isNew, setIsNew] = useState<boolean>(isNewTransaction)
  const user = useSelector((state : RootStateOrAny) => state.user.user)
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([])

  const handleSubmit = () => {
    setIsNew(isNewTransaction)
    // Si la dernière étape est valide, on submit le formulaire
    history.push('/')
  }

  const saveBrandList = async () => {
    if (isNew && !transaction.id) {
      setIsNew(false)
      return await TransactionService.createTransaction(transaction, PROCEDURE_RENEWAL.value, ['renewalDetails', 'process', 'depositors'])
    } else {
      return await TransactionService.updateTransactionBDDFromStore(transaction, ['renewalDetails', 'process', 'depositors'], documents)
    }
  }

  /**
   * Edition de la liste des déposants
   * @returns
   */
  const onDepositorsEdited = async (): Promise<Transaction|null> => {
    // Supprime les déposants non conformes
    const newContributorList : Contributor[] = transaction.depositors ? transaction.depositors.filter((contributor) =>
      !containsErrors(ContributorValidator.validateContributor([contributor], transaction, CONTRIBUTOR_DEPOSITORS.value)))
      : []
    return await TransactionService.updateTransactionBDDFromStore({ ...transaction, process: CommonContributorService.areContributorsTheSame(transaction?.renewalDetails?.titles?.[0]?.holders, transaction?.depositors) ? transaction.process : PROCESS_NORMAL, depositors: newContributorList }, [DEPOSIT_CONTRIBUTORS_TYPES.DEPOSITORS.value, 'process'])
  }

  useEffect(() => {
    // Initialisation du dépôt à modifier
    if (!isNewTransaction) {
      TransactionService.getTransaction(match.params.id).then(result => {
        dispatch(storeTransactionUpdate(result))
        result.documents && setDocuments(result.documents)
      })
    } else {
      /** Lors de l'initialisation, le type de renouvellement doit être à TOTAL */
      dispatch(storeTransactionInitRenewal({ type: RENEWAL_TYPE_TOTAL.value as string }))
      // Texte d'information sur le RGPD affiché en popin
      ContentService.getEditorialBlockByCode('RGPD-RENEWAL').then((res: EditorialBlock) => {
        if (res.content) {
          setRdpdContent(res.content)
          setShowRgpd(true)
        }
      })
    }
  }, [isNewTransaction])

  useEffect(() => {
    ContentService.getDocumentTypes(PROCEDURE_RENEWAL.value).then((response: DocumentType[]) => {
      setDocumentTypes(response)
    })
    ConfigurationService.getConfigurationsValues(['ELECTRONIC_NOTIF']).then(result =>
      localStorage.setItem('ELECTRONIC_NOTIF', result?.[0].value)
    )

    return () => {
      dispatch(storeTransactionRemove())
    }
  }, [])

  useEffect(() => {
    setDocuments(transaction.documents ?? [])
  }, [transaction.documents])

  const onDocumentEdited = (editedDocument: TransactionDocument): void => {
    setDocuments([...documents.map((document: TransactionDocument) =>
      document.name === editedDocument.name && document.createdAt === editedDocument.createdAt
        ? editedDocument : document
    )])
  }

  const onDocumentAdded = (document: TransactionDocument) => {
    setDocuments([...documents, document])
  }

  const onDocumentDeleted = (deletedDocument: TransactionDocument) => {
    setDocuments(documents.filter((file: TransactionDocument) =>
      !(deletedDocument.createdAt === file.createdAt && deletedDocument.internalName === file.internalName && deletedDocument.filename === file.filename)))
  }

  return (
    <>
      <FilAriane>
        <Link to='/'><FormattedMessage id='breadcrumb_home' /></Link>
        <span><FormattedMessage id='breadcrumb_new_renewal' /></span>
      </FilAriane>

      <StepContainer
        className='mb-5 is-validated'
        listTitle={<FormattedMessage id='breadcrumb_new_renewal' />}
        onCancel={() => history.push('/')}
        onSubmit={handleSubmit}
        required={required}
        setIsRequired={setIsRequired}
        views={[
          {
            id: 'renewal_titles',
            label: <FormattedMessage id='renewal_brand_titles' />,
            onGoToStep: async () => saveBrandList(),
            component: (
              <Titles
                transaction={transaction}
                fieldStatus={fieldStatus}
                origin={['FR']}
                isMonoTitle
              />
            ),
            validateGoToStep: () => TitlesValidator.validateOnChangeStep(transaction)
          },
          {
            id: 'contributors',
            label: <FormattedMessage id='contributor_title' />
          },
          {
            id: 'contributors_depositors',
            label: <FormattedMessage id='contributor_depositors_renewal' />,
            level: 1,
            component: (
              <ContributorList
                openOnFirstOne={!transaction.process || transaction.process !== PROCESS_FAST_TRACK_RENEWAL}
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={RENEWAL_CONTRIBUTORS_TYPES.DEPOSITORS.value}
                contributorTypeLabel={`${CONTRIBUTOR_DEPOSITORS.value}_renewal`}
                contributorTypeToCopy={[
                  RENEWAL_CONTRIBUTORS_TYPES.AGENT.value,
                  RENEWAL_CONTRIBUTORS_TYPES.RECIPIENT.value,
                  RENEWAL_CONTRIBUTORS_TYPES.SIGNATORY.value
                ]}
                optionForm={RENEWAL_CONTRIBUTORS_OPTIONS.depositors}
                procedure={PROCEDURE_RENEWAL.value}
                validateContributor={(contributor: Contributor, procedure?: string | undefined) => ContributorValidator.validateContributor(contributor, procedure, DEPOSIT_CONTRIBUTORS_TYPES.DEPOSITORS.value, false)}
                setIsRequired={setIsRequired}
              />
            ),
            stepButtonsAsChildren: true,
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepDepositorsDeposit,
            onGoToStep: onDepositorsEdited
          },
          {
            id: 'contributors_agent',
            label: <FormattedMessage id='contributor_agent' />,
            level: 1,
            component: (
              <ContributorAgent
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorTypeToCopy={[
                  RENEWAL_CONTRIBUTORS_TYPES.DEPOSITORS.value,
                  RENEWAL_CONTRIBUTORS_TYPES.RECIPIENT.value,
                  RENEWAL_CONTRIBUTORS_TYPES.SIGNATORY.value
                ]}
                option={RENEWAL_CONTRIBUTORS_OPTIONS.agent}
                procedure={PROCEDURE_RENEWAL.value}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepAgentDeposit,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [RENEWAL_CONTRIBUTORS_TYPES.AGENT.value])
              .then((transaction: Transaction|null) => {
                if (transaction) {
                  return ContributorService.updateDocumentFromContributor(transaction, RENEWAL_CONTRIBUTORS_TYPES.AGENT.value, documents, setDocuments)
                }
              })
          },
          {
            id: 'contributors_recipient',
            label: <FormattedMessage id='contributor_recipient' />,
            level: 1,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={RENEWAL_CONTRIBUTORS_TYPES.RECIPIENT.value}
                contributorTypeToCopy={[
                  RENEWAL_CONTRIBUTORS_TYPES.DEPOSITORS.value,
                  RENEWAL_CONTRIBUTORS_TYPES.AGENT.value,
                  RENEWAL_CONTRIBUTORS_TYPES.SIGNATORY.value
                ]}
                optionForm={RENEWAL_CONTRIBUTORS_OPTIONS.recipient}
                procedure={PROCEDURE_RENEWAL.value}
                defaultContributor={{ ...DEFAULT_CONTRIBUTOR, email: user.email, consentNotificationByEmail: user.consentNotificationByEmailPreferences }}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepRecipient,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [RENEWAL_CONTRIBUTORS_TYPES.RECIPIENT.value])
          },
          {
            id: 'contributors_signatory',
            label: <FormattedMessage id='contributor_signatory' />,
            level: 1,
            component: (
              <ContributorFrontForm
                transaction={transaction}
                fieldStatus={fieldStatus}
                contributorType={RENEWAL_CONTRIBUTORS_TYPES.SIGNATORY.value}
                optionForm={{ ...RENEWAL_CONTRIBUTORS_OPTIONS.signatory, allFieldReadOnly: transaction.process === PROCESS_FAST_TRACK_RENEWAL }}
                procedure={PROCEDURE_RENEWAL.value}
                defaultContributor={ContributorService.buildDefaultSignatoryFromUser(user)}
                actionWithAddressBook={false}
                setIsRequired={setIsRequired}
              />),
            validateGoToStep: ContributorValidatorAndSaveStore.validateOnChangeStepSignatoryDeposit,
            onGoToStep: () => TransactionService.updateTransactionBDDFromStore(transaction, [RENEWAL_CONTRIBUTORS_TYPES.SIGNATORY.value])
              .then((transaction: Transaction|null) => {
                if (transaction) {
                  return ContributorService.updateDocumentFromContributor(transaction, RENEWAL_CONTRIBUTORS_TYPES.SIGNATORY.value, documents, setDocuments)
                }
              })
          },
          {
            id: 'renewal_demand_details',
            label: <FormattedMessage id='renewal_demand_details' />,
            component: (
              <RenewalOptions transaction={transaction} documents={documents} fieldStatus={fieldStatus} documentTypes={documentTypes} onDocumentEdited={onDocumentEdited} onDocumentAdded={onDocumentAdded} onDocumentDeleted={onDocumentDeleted} />),
            onGoToStep: async () => TransactionService.updateTransactionBDDFromStore(transaction, ['renewalDetails', 'internationalExtensions'], documents),
            validateGoToStep: () => RenewalOptionsValidator.validateStep(documents)
          },
          {
            id: 'overview',
            label: <FormattedMessage id='overview_title' />,
            component: <Recap fieldStatus={fieldStatus} transaction={transaction} />,
            validateGoToStep: () => OverviewValidator.validateOnChangeStep(transaction)
          },
          {
            id: 'payment',
            noStepButtons: true,
            label: <FormattedMessage id='payment_title' />,
            component: (
              <PaymentForm
                transaction={transaction}
                label={PAYMENT_LABELS.INITIAL}
                objectName={PAYMENT_OBJECTS.TRANSACTION}
                urlType={PAYMENT_URL_RENEWAL}
                isMultiTransaction={transaction.renewalDetails?.titles && transaction.renewalDetails.titles.length > 1}
              />
            )
          }
        ]}
      />
      <ModalComponent
        title={<FormattedMessage id='rgpd_title' />}
        isNotCancellable
        customContent={() => <div dangerouslySetInnerHTML={{ __html: rgpdContent }} />}
        handleClose={() => setShowRgpd(false)}
        show={showRgpd}
        onClick={() => setShowRgpd(false)}
        customButtonId='common_start_form'
        size='xl'
      />
    </>
  )
}

export default withRouter(RenewalForm)
